import React, { useEffect, useState } from "react";
import SectionDivider from "../components/Employers/SectionDivider";
import SummaryBlock from "../components/Home/SummaryBlock";
import axios from "axios";
import Layout from "../components/layout";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Testimonial from "../components/Employers/Testimonial";
import LogoSection from "../components/Home/LogoSection";
import FlatButton from "../components/Reusables/FlatButton";
import styled from "styled-components";
import { Link } from "gatsby";
import UniversityStudents from "../components/Students/UniversityStudents";
import HoverEffectBlocks from "../components/About/HoverEffectBlock";
import EligibilityAndBenefits from "../components/Students/EligibilityAndBenefits";
import Accordion from "../components/Reusables/Accordion";
import LinksOnHover from "../components/Involved/LinksOnHover";
import Donations from "../components/Involved/Donations";

import hero from "../images/pageImages/involved/Hero.jpg";
import wtgi from "../images/pageImages/involved/wtgi.jpg";
import InvolvementBlocks from "../components/Involved/InvolvementBlocks";
import VolunteerModal from "../components/Involved/VolunteerModal";
import ReactMarkdown from "react-markdown";
import ProgressBar from "react-bootstrap/ProgressBar";
import { isMobile } from "react-device-detect";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
import DescriptionBlock from "../components/Home/DescriptionBlock"
const StyledWrapper = styled.div`
  .flat-donation-button {
    color: white;
    text-decoration: none;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }
  .donation {
    padding: 3% 0%;
  }
  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
    padding-top: 40px;
  }
  .progress {
    height: 60px;
    background-color: rgb(93, 185, 219);
    width: 90%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .progress-bar {
    background-color: white;
    color: black !important;
    font-size: 20px !important;
  }
  .progress-main {
    background-color: #464fd0;
    padding: 50px 40px 20px;
  }
  .progress-bar {
    transition: 1s;
  }
  .progress-main > h1 {
    padding-bottom: 20px;
    color: white;
    @media (max-width: 991px) {
      padding-bottom: 0px;
    }
  }
  .progress-amount > p {
    font-size: 20px;
    color: white;
    font-weight: 600;
    @media (max-width: 991px) {
      font-size: 15px;
      margin-bottom: 10px;
    }
  }
  .progress-text > p {
    font-size: 18px;
    color: white;
    width: 90%;
    @media (max-width: 991px) {
      font-size: 14px;
      width: 100%;
      padding: 10px 0px 0px;
    }
  }
  .progress-row {
    display: flex;
    align-items: center;
    padding: 30px 0px;
    @media (max-width: 991px) {
      padding: 10px 0px 30px;
    }
  }
  .progress-row > p {
    margin-bottom: 0px;
    padding: 0px 15px;
    font-size: 20px;
    color: white;
    font-weight: 600;
    @media (max-width: 991px) {
      font-size: 15px;
      padding: 0px;
    }
  }
  .fundraising {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
  }
  @media (min-width: 991px) {
    .campaign-image {
      height: 450px;
      display: flex;
      justify-content: center;
    }
  }
  @media (min-width: 2000px) {
    .campaign-image {
      height: 600px;
      object-fit: cover;
    }
  }
  .involve .subtext{
    font-size: 1rem;
    line-height: 1.5;
  }
  .fundraising .subtext{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 80px;
  }
`;

const summary = [
  {
    intro:
      "There are many ways you can help us boost social mobility: by becoming a part of the community and playing your part in helping us to boost social mobility; by championing our work within your organisation and by setting up synergies with your recruitment, diversity or CSR team.",
    title: "Ways to get involved",
    description: "",
    image: `${wtgi}`,
  },
];

const heroImage = { hero: `${hero}` };

// const partnerHeading = { title: "OUR UNIVERSITY PARTNERS INCLUDE" }
// const partnerUniHeading = { title: "OTHER UNIVERSITY BASED PARTNER INCLUDE" }
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Involved />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/involved-page`);
};
const Involved = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("involved", fetchData);
  const [modalOpen, setModalOpen] = React.useState();
  const [progressBar, setProgressBar] = useState(null);
  const [offset, setOffset] = useState(0);
  const mainUrl = process.env.GATSBY_API_URL;
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  useEffect(() => {
    if (window.location.href.includes("isVolunteer=true")) {
      setModalOpen(true);
    }
    if (modalOpen == false) {
      window.history.replaceState({}, document.title, "/involved");
      setModalOpen(false);
    }
  }, [modalOpen]);
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  useEffect(() => {
    if (newData && offset >= 200) {
      setProgressBar(
        (newData.ProgressBar[0].Amount / newData.ProgressBar[0].TotalAmount) *
          100
      );
    }
    if (newData && isMobile) {
      if (offset >= 1000) {
        setProgressBar(
          (newData.ProgressBar[0].Amount / newData.ProgressBar[0].TotalAmount) *
            100
        );
      }
    }
  });
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}

        {newData &&
          newData.showCovidCampaign &&
          newData.CovidCampaign.length > 0 &&
          newData.CovidCampaign.map(item => (
            <div
              className="container-fluid white-background"
              id="fundraising"
            >
              <div className="row">
                <div className="col-12 col-xl-7 col-md-6 d-flex flex-column justify-content-center padding-left-8 padding-right-8 mission order-2 order-md-1">
                  {/* <ReactMarkdown className="campaign-text">
                    {item.description}
                  </ReactMarkdown> */}
                  <div className="involve">
                  <DescriptionBlock description={item.description}  />
                  </div>
                </div>
                <div className="col-12 col-xl-5 col-md-6 order-1 order-md-2">
                  {item.image && (
                    <img
                      className="img-fluid mx-auto campaign-image"
                      src={mainUrl + item.image.url}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        {newData &&
          newData.showProgressBar &&
          newData.ProgressBar.length > 0 &&
          newData.ProgressBar.map(item => (
            <div className="pt-5 padding-left-8 padding-right-8 white-background">
              <div className="progress-main">
                <h1 className="d-flex">
                  {item.title}
                  <span className="d-sm-block d-none">
                    &nbsp;&nbsp;:&nbsp;&nbsp;{item.AmountText}
                  </span>
                </h1>
                <div className="progress-row">
                  <ProgressBar
                    now={progressBar}
                    label={`${Math.floor(
                      (item.Amount / item.TotalAmount) * 100
                    )}%`}
                  />
                  <p className="d-none d-sm-block">{item.TotalAmountText}</p>
                </div>
                <div className="progress-amount flex-direction-column d-block d-sm-none">
                  <p>Goal - {item.TotalAmountText}</p>
                  <p>Received - {item.AmountText}</p>
                </div>
              </div>
            </div>
          ))}
        {newData &&
          newData.DonationCta.length > 0 &&
          newData.DonationCta.map(item => (
            <div id="donation" className="py-5 white-background">
              <Link className="flat-btn-link" to={item.link}>
                <div className="donation" style={{ background: item.bgColor }}>
                  <span className="flat-donation-button">{item.title}</span>
                </div>
              </Link>
            </div>
          ))}

        {/* <HeroImages heroImage={heroImage} /> */}
        {newData && newData.showSummary && newData.Summary && (
          <div className="container-fluid margin-top-100" id="getInvolved">
            <div className="row mission-row">
              <div className="col-12 col-md-6 flex-column justify-content-center padding-left-8 padding-right-8 mission order-2 order-md-1">
                <h1>{newData.Summary.title}</h1>
                {newData.DonateAccordian.length > 0 && (
                  <Accordion
                    accordion={newData.DonateAccordian}
                    accordionHeading=""
                    className="margin-top-0"
                    forScreen="getInvolved"
                  />
                )}
              </div>
              <div className="col-12 col-md-6 order-1 order-md-1">
                {newData.Summary.image && (
                  <img
                    className="img-fluid mx-auto img-size-donate"
                    src={mainUrl + newData.Summary.image.url}
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {/* <LinksOnHover /> */}
        {/* <InvolvementBlocks data={pageData.InvolvementBlock} /> */}

        {newData && newData.showDonate && (
          <Donations
            donationLinks={newData.Donate}
            donationSummary={newData.DonationSummary}
          />
        )}
        {modalOpen && (
          <VolunteerModal setModalOpen={setModalOpen} modalOpen={modalOpen} />
        )}
        {newData && newData.showFundraising && (
          // <ReactMarkdown className="fundraising padding-left-8 padding-right-8">
          //   {newData.Fundraising}
          // </ReactMarkdown>
          <div className="fundraising padding-left-8 padding-right-8">
          <DescriptionBlock description={newData.Fundraising}  />
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
};

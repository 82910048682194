import React, {useState, useRef} from 'react'
import { Button,Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import ReCAPTCHA from "react-google-recaptcha";

const StyledWrapper = styled.div`
  background-color: white;
  width: 80%;
  padding: 5% 8%;
  overflow: scroll;

  @media (max-width: 600px) {
    width: 95%;
    padding-top: 8%;
    padding-bottom: 8%;
  }

  .form-control {
    height: 69px;
    width: 90%;
    background-color: #ececec;

    @media (max-width: 600px) {
      height: 50px;
      width: 100%;
    }
  }

  .form-label {
    font-family: "Nunito Sans";
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 20px;
    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .submit-form {
    height: 50px;
    width: 180px;
    background-color: #464fd0;
  }

  .cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 8px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .checkbox-link{
    display:inline-block;
    color: black;
    text-decoration: underline;
  }

  .fail-text{
    padding-top: 10px;
    padding-right: 80px;
    font-weight:bolder;
    color:#E43D5A;
  }

  .required-text{
    color:#E43D5A;
  }

  .captcha{
    padding:20px 0 10px 0;
  }

  .policy-statement{
    padding-right:20%;
  }

  .link{
    text-decoration:underline;
    color:black;
  }

  iframe{
    height:100px
  }
`

export default function VolunteerForm({handleClose}) {
    const { register, handleSubmit, watch, errors, setValue } = useForm({mode: "onChange"})
    const [error, setError] = useState("")
    const enkiUrl = process.env.GATSBY_ENKI_URL;
    const [dataPolicy, setDataPolicy] = useState(false)
    const g_captcha = process.env.GATSBY_RECAPTCHA_KEY;
    let captchaRef = useRef(null)
    const [feedbackMsg, setFeedbackMsg] = useState(null)

    const onSubmit = data => {
      const captchaValue = captchaRef.current.getValue();
      if (!captchaValue) {
        setFeedbackMsg("Select 'I'm not a robot'")
        return
      }
        fetch(`${enkiUrl}/form/volunteer-registration`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              ...data,
              "g-recaptcha-response": captchaValue
            })
        }).then(function(response){
            if(response.status == 200){
                handleClose();
            } else {
                return response.json().then(data => setError(data.message[0]));
            }
        }).catch(err => console.log(err));
    }

    return (
        <StyledWrapper>
            <h1 className="mb-2 mb-md-4">VOLUNTEER AT UPREACH</h1>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Label>First Name<span className="required-text">*</span></Form.Label>
                <Form.Control
                    type="text"
                    ref={register({ required: true})}
                    name="vol_firstname"
                    placeholder="Enter your first name"
                />
                <Form.Label>Last name<span className="required-text">*</span></Form.Label>
                <Form.Control
                    type="text"
                    ref={register({required: true})}
                    name="vol_lastname"
                    placeholder="Enter your last name"
                />
                <Form.Label>Contact Number<span className="required-text">*</span></Form.Label>
                <Form.Control
                    ref={register({required: true})}
                    name="vol_contact"
                    placeholder="Enter your contact number"
                    type="text"
                />

                <Form.Group controlId="formGridEmail">
                <Form.Label>Email Address<span className="required-text">*</span></Form.Label>
                <Form.Control
                    ref={register({required: true})}
                    name="vol_email"
                    placeholder="Enter your email address"
                    type="email"
                />
                </Form.Group>

                <Form.Label>Current Employer<span className="required-text">*</span></Form.Label>
                <Form.Control
                    ref={register({required: true})}
                    name="vol_current_employer"
                    placeholder="Enter your current employer"
                    type="text"
                />

                <Form.Label>How can you help<span className="required-text">*</span></Form.Label>
                <Form.Control
                    ref={register({required: true})}
                    name="description"
                    placeholder=""
                    type="text"
                />

                <Form.Label>Previous experience/skills<span className="required-text">*</span></Form.Label>
                <Form.Control
                    ref={register({required: true})}
                    name="previous"
                    placeholder=""
                    type="text"
                />
                {feedbackMsg && <h3>{feedbackMsg}</h3>}
                <div className="captcha">
                  <ReCAPTCHA
                      sitekey= {`${g_captcha}`}
                      onChange={val => {
                        setValue("g-recaptcha-response", val, true)
                      }}
                      name="g-recaptcha-response"
                      ref={captchaRef}
                    />
                </div>

                <div className="policy-statement">
                  You can change your mind at any time by replying to any email you receive from us or contacting us on 
                  volunteer@upreach.org.uk. For more information please read our <a href="/privacy-notice-and-use-of-cookies" className="link">Privacy Policy</a>. 
                  By clicking “submit” below, you agree that we may process your information in accordance with these terms.
                </div>
                <span onClick={handleClose} className="mr-5 mr-md-5 cancel-btn">
                    CANCEL
                </span>

                <Button className="submit-form mt-3" type="submit">
                    SUBMIT
                </Button>
                {error != "" ? <p className="fail-text">{error}</p> : <></>}
            </Form>
        </StyledWrapper>
    );
}
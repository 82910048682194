import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`

  color: white;

.involvemnet__box__padding {
    padding: 8%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 200px;
    @media (max-width: 600px){
      height: 160px;
    }
}

/* .involvement__row {
    margin-top: 100px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
  }
  .involvement__row > [class*="col-"] {
    display: flex;
    flex-direction: column;
  } */

  .flat-button {
    font-size: 24px;
    font-weight: bold;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .onhover-emails {
    color: white;
    text-decoration: underline;
  }

  .onhover-display {
    display: none;
  }

  .involvemnet__box__padding:hover .onhover-display{
      display: block;
  }
  .involvemnet__box__padding:hover .flat-button{
      display: none !important;
  }
`;


const Links = [
  {
    title: "FUNDRAISE",
    color: "rgb(70, 79, 208)",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Organise fun ways to raise awareness of our cause, inspire generosity and support our programmes.",
    subscribe: "subscribe to newsletter"
  },
  {
    title: "MENTOR",
    color: "rgb(48, 69, 99)",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Facilitate stronger relationships for students with potential and partner employers.",
  },
  {
    title: "VOLUNTEER",
    color: "rgb(93, 185, 219)",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Share your knowledge, skills and experience through training and events.",
  },
  {
    title: "PROMOTE",
    color: "rgb(255, 134, 0)",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Benefit from our LinkedIn network while discussing professional development and social mobility challenges.",
  },
]

const InvolvementBlocks = ({data}) => {
  return (
    <StyledWrapper className="margin-top-100" id="involvementLinks">
      <div className="container-fluid padding-left-8 padding-right-8">
          <div className="row involvement__row">
        {React.Children.toArray(
          data.map(items => (
            <div className="col-12 col-md-6 pt-4">
                <div className="col-12 involvemnet__box__padding" style={{ backgroundColor: items.color }}>
              <span className="flat-button text-center d-block mb-3">{items.title}</span>
              <div className="onhover-display">
              <p className="subtext text-center">{items.shortDescription}</p>
                <p className="my-0 subtext text-center">
                  Contact Us: &nbsp;
                  <a
                    className="onhover-emails"
                    href="mailto:olivia.reeve@upreach.org.uk?cc=team@upreach.org.uk"
                  >
                    {items.email}
                  </a>
                </p>
                  <a
                    className="onhover-emails d-block text-center"
                    href="http://eepurl.com/glSQQD"
                    target="_blank"
                  >
                    {items.subscribe}
                  </a>
                </div>
            </div>
            </div>
          ))
        )}
        </div>
      </div>
    </StyledWrapper>
  )
}

export default InvolvementBlocks

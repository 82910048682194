import React from "react"
import FlatButton from "../Reusables/FlatButton"
import styled from "styled-components"
import ReactDOM from "react-dom"
import { Link } from "gatsby"

const StyledWrapper = styled.div`
  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  .background-color {
    padding: 4%;
    max-height: 240px;
  }

  .onhover-display {
    display: none;
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    @media (max-width: 600px) {
        font-size: 18px;
        line-height: normal;
    }
  }

  .background-color:hover .onhover-display {
      display: block;
      @media (max-width: 600px) {
            margin-top: 30px;
      }
  }

  .background-color:hover .flat-button {
      display: none;
      @media (max-width: 600px) {
          display: block;
      }
  }

  .onhover-emails {
      color: white;
  }
`

const Links = [
  {
    title: "FUNDRAISE",
    color: "#5DB9DB",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Organise fun ways to raise awareness of our cause, inspire generosity and support our programmes.",
  },
  {
    title: "MENTOR",
    color: "#E43D5A",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Facilitate stronger relationships for students with potential and partner employers.",
  },
  {
    title: "VOLUNTEER",
    color: "#E99034",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Share your knowledge, skills and experience through training and events.",
  },
  {
    title: "PROMOTE",
    color: "#F2B842",
    email: "olivia.reeve@upreach.org.uk",
    shortDescription:
      "Benefit from our LinkedIn network while discussing professional development and social mobility challenges.",
  },
]

const LinksOnHover = () => {
  return (
    <StyledWrapper className="margin-top-100" id="involvementLinks">
      <div className="container-fluid" >
        {React.Children.toArray(
          Links.map(items => (
            <div
              className="row background-color padding-left-8 padding-right-8"
              style={{ backgroundColor: items.color }}
            >
                <span className="flat-button my-4">{items.title}</span>
                <div className="onhover-display mx-auto">
                <p className="mb-0">{items.shortDescription}</p>
                <p className="my-0">
                    Contact Us: <a className="onhover-emails" href='mailto:olivia.reeve@upreach.org.uk?cc=team@upreach.org.uk'>{items.email}</a>
                </p>
                </div>
            </div>
          ))
        )}
      </div>
    </StyledWrapper>
  )
}

export default LinksOnHover
